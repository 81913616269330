<template>
  <div >
    <el-card v-if="!is_preview">
        <h2 class="text-center">Generate Package Invoice</h2>
        <el-form
            :model="service_invoice"
            label-position="top"
            label-width="100px"
            ref="service_invoice"
            size="default"
        >
      <!--  invoice -->
        <div>
          <el-row :gutter="20">
            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item
                label="Select Date"
                prop="invoice_date"
                :rules="[
                  {
                    required: true,
                    message: 'Please input invoice date',
                    trigger: 'blur',
                  }
                ]">
                <el-date-picker
                    class="w-100"
                    v-model="service_invoice.invoice_date"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    @change="dateChanged()"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item
                label="Search Patient"
                prop="patient_id"
                :rules="[
                  {
                    required: true,
                    message: 'Please input patient',
                    trigger: 'blur',
                  }
                ]">
                <el-select
                    v-model="service_invoice.patient_id"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Please enter phone number"
                    :remote-method="searchGlobalPatient"
                    :loading="loading"
                    clearable
                    class="w-100"
                    @change="checkDoctorReferral()"
                    :disabled="$route.query.patient_name ? true : false"
                >
                  <el-option
                      v-for="item in patients"
                      :key="item.id"
                      :label="item.fullname"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item
                label="Select Package"
                prop="orko_therapy_package_id"
                :rules="[
                  {
                    required: true,
                    message: 'Please input package',
                    trigger: 'blur',
                  }
                ]">
                <el-select
                    @change="clickPackage"
                    class="w-100"
                    v-model="service_invoice.orko_therapy_package_id"
                    clearable
                    filterable
                    @clear="clearPackageSelection"
                    placeholder="Select Service"
                >
                  <el-option
                      v-for="item in packages"
                      :key="item.id"
                      :label="item.label?'Package No - ' + item.package_no + ' ('+item.label+')':'Package No - ' + item.package_no"
                      :value="item.id"
                  >
                  <span>Package No - {{ item.package_no }} {{ item.label?' ('+item.label+')':''}}</span>
                </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item label="Select Service Type" prop="invoice_service_type" :rules="[
                      {
                        required: true,
                        message: 'Please select service type',
                        trigger: 'blur',
                      }
                    ]">
                <el-select
                    @change="getServiceType"
                    class="w-100"
                    v-model="service_invoice.invoice_service_type"
                    clearable
                    filterable
                    placeholder="Select Service Type"
                    required
                >
                  <el-option
                      v-for="item in invoiceServiceType"
                      :key="item.label"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8" v-if="!service_invoice.prescription_id">
              <el-form-item
                label="Select Therapies"
                prop="therapyIds"
                :rules="[
                  {
                    required: true,
                    message: 'Please input therapy',
                    trigger: 'blur',
                  }
                ]"
                >
                <el-select
                  clearable
                  v-model="service_invoice.therapyIds"
                  multiple
                  placeholder="Select Therapies"
                  filterable
                  class="w-100"
                >
                  <el-option
                    v-for="item in therapies"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  />
                </el-select>
                </el-form-item>
            </el-col>

            <el-col :sm="24" :md="8" :lg="8" v-if="service_invoice.orko_therapy_package_id && package && package.package_no">
              <el-form-item
                label="No of Sessions Patient wants to pay?"
                prop="no_of_session_payment"
                :rules="[
                  {
                    required: true,
                    message: 'Please No of Sessions Patient wants to pay',
                    trigger: 'blur',
                  }
                ]"
                >
                <el-select
                  @change="calculatePaidAmount"
                    class="w-100"
                    v-model="service_invoice.no_of_session_payment"
                    clearable
                    filterable
                    placeholder="Select No of Session"
                    required
                >
                  <el-option
                      v-for="sNo in package.no_of_sessions"
                      :key="sNo"
                      :label="sNo"
                      :value="sNo"
                  />
                </el-select>
                </el-form-item>
            </el-col>

            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item label="TP/FP Phase?" prop="is_followup" :rules="[
                      {
                        required: true,
                        message: 'Please Select Phase',
                        trigger: 'blur',
                      }
                    ]">
                <el-select
                          v-model="service_invoice.is_followup"
                          placeholder="Select Phase"
                          clearable
                          class="w-100"
                      >
                        <el-option
                            v-for="item in phaseType"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                        />
                </el-select>
              </el-form-item>
            </el-col>
            
          </el-row>
          <br/><br/>
          <table v-loading="loading" v-if="package && package.package_no">
            <tr class="text-center">
              <th>Service</th>
              <th>Unit Price</th>
              <th>Discount</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr>
              <td>
                Session Booking (Package No: {{  package.package_no }}, No of Sessions: {{  package.no_of_sessions }})
              </td>
              <td>{{ package.price }}</td>
              <!-- <td>{{ calculateMultiplePercentage(package.discount) }}%</td> -->
              <td>{{ calculateMultiplePercentage(0) }}%</td>
              <td>1</td>
              <td>
                {{ subtotal }}
              </td>
            </tr>

            <tr>
              <td colspan="4" class="text-right">Sub Total</td>
              <td>{{ subtotal }}</td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="membership_type_id">
                      <el-select
                          @change="changeEmployeeType"
                          class="w-100"
                          v-model="service_invoice.membership_type_id"
                          clearable
                          filterable
                          placeholder="Select Membership type"
                      >
                        <el-option
                            v-for="item in memberTypes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="employee_id">
                      <el-select
                          @change="checkMembershipType"
                          v-model="service_invoice.employee_id"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Please enter name or phone number"
                          :remote-method="getEmployees"
                          :loading="loading"
                          clearable
                          class="w-100"
                      >
                        <el-option
                            v-for="item in employees"
                            :key="item.id"
                            :label="item.fullname"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :sm="24" :md="6" :lg="6">
                      <el-button type="primary" @click="createNewUser()" v-if="service_invoice.membership_type_id == 'Doctor Referral' || service_invoice.membership_type_id == 'General Referral'">
                        Create New
                      </el-button> 
                    </el-col>

                  <el-col :sm="24" :md="18" :lg="18">
                    Discount(%)
                    <el-input
                      placeholder="Discount reason"
                      class="w-100"
                      :input-style="{ textAlign: 'center' }"
                      v-model="service_invoice.discount_reason"
                      clearable
                    />
                      </el-col>
                </el-row>
              </td>
              <td style="width: 150px;text-align: center;">
                <el-input
                    input-style="text-align: center"
                    class="w-100"
                    v-model="service_invoice.discount_percentage"
                    clearable
                    type="number"
                    @input="calculatePaidAmount"
                />
              </td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">
                  <el-checkbox  v-model="is_welfare_discount" @change="checkWelfareDiscount" label="Welfare Discount" name="type" />
              </td>
              <td>
                <el-input
                    v-if="welfare_details.expected_discount_amount"
                    placeholder="Welfare Discount Amount"
                    class="w-100"
                    :input-style="{ textAlign: 'center'}"
                    type="number"
                    v-model="service_invoice.welfare_discount"
                    :max="welfare_details.discount_due"
                    @input="calculatePaidAmount"
                    clearable
                />
                <span v-else>0.00</span> 
              </td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">Total</td>
              <td>{{ total }}</td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">
                <strong style="color: red; font-size: 18px;">Paid Amount</strong>
              </td>
              <td class="text-center">
                <strong style="color: red; font-size: 18px;">{{ service_invoice.total_paid }}</strong>
                <!-- <el-input disabled type="number" v-model="service_invoice.total_paid" input-style="text-align: center;" :min="0" :step="50" :max="total" @input="checkItemValue(0, total, service_invoice, 'total_paid')"/> -->
              </td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">Total Due</td>
              <td>{{ Math.round(total - service_invoice.total_paid, 2) }}</td>
            </tr>
          </table>
          <br/>
          <el-form-item label="Comment" prop="comment">
            <el-input v-model="service_invoice.comment" type="textarea"/>
          </el-form-item>
        </div>
        <!-- session booking -->
        <div>
          <br/>
          <h2 class="text-center">Session Booking</h2>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <div class="new_patient_section d-flex">
                <div class="w-100">
                  <el-form-item
                    label="Select Therapy Assistant"
                    prop="doctor_id"
                    :rules="[
                      {
                        required: true,
                        message: 'Please input doctor',
                        trigger: 'blur',
                      }
                    ]">
                  <el-select class="w-100" v-model="service_invoice.doctor_id" filterable clearable
                             placeholder="Select Therapy Assistant">
                    <el-option
                        v-for="item in assistants"
                        :key="item.id"
                        :label="item.username"
                        :value="item.id"
                    />
                  </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">

                <el-form-item label="Appointment type" prop="service_type">
                  <el-radio-group v-model="service_invoice.service_type">
                    <el-radio label="visit" @input="clickVisit"/>
                    <el-radio label="virtual" @input="clickVirtual"/>
                  </el-radio-group>
                </el-form-item>

            </el-col> 
          </el-row>

          <el-row :gutter="20" class="mb-5"
           v-if="service_invoice.doctor_id"
           v-for="(appointment, index) in service_invoice.appointments"
           :key="index"
           >
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="row-gap">
              <div class="d-flex justify-content-between">
                <div class="w-100">
                  <el-form-item
                  :prop="'appointments.' + index + '.schedule_date'"
                  :rules="{
                    required: true,
                    message: 'Schedule date and Chamber slot required',
                    trigger: 'blur',
                  }">
                  <el-date-picker
                      class="app_date_picker w-100"
                      popper-class="my-datepicker"
                      v-model="appointment.schedule_date"
                      type="date"
                      placeholder="Pick schedule date"
                      size="default"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      :disabled-date="disabledDate"
                      @change="clickDate(appointment.schedule_date, index)"
                  />
                </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" class="row-gap">

                  <div v-if="appointment.chambers.length && appointment.schedule_date">

                    <ChamberWithSlots @workPlaceId="workPlaceId" :app_index="index" :chambers="appointment.chambers" v-model="appointment.schedule_started_at"
                                      @slotNull="formDataNull"/>

                  </div>
            </el-col>
          </el-row>
          <br/>
        </div>
          <div class="text-center">
            <el-button type="danger">
              Cancel
            </el-button>
            <el-button type="primary" @click="previewInvoice('service_invoice')">
              Preview
            </el-button>
          </div>
        </el-form>
    </el-card>
    <PackageInvoicePreview
      v-else
      :service_invoice="service_invoice"
      :packages="packages"
      :patients="patients"
      :memberTypes="memberTypes"
      :total="total"
      :subtotal="subtotal"
      :employees="employees"
      :assistants="assistants"
      :welfare_details="welfare_details"
      @isPreview="is_preview=false"
    />
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ChamberWithSlots from '../../components/ChamberWithSlots';
import PackageInvoicePreview from './PackgeInvoicePreview.vue';
import { ElMessage } from 'element-plus';
export default {
  name: 'PackageInvoice',
  components: {
    ChamberWithSlots,
    PackageInvoicePreview,
  },
  data() {
    return {
      loading: false,
      service_invoice: {
        prescription_id: this.$route.query.p ? this.$route.query.p : '',
        patient_id: this.$route.query.patient_id ? this.$route.query.patient_name : '',
        appointment_id: '',
        orko_therapy_package_id: '',
        discount_percentage: 0,
        comment: '',
        discount_reason: '',
        qty: 1,
        membership_type_id: '',
        invoice_date: this.todayDate(),
        employee_id: '',
        center_code: '',
        service_type: 'visit',
        appointments: [],
        product_type: 'therapy_session',
        subtotal: '',
        total: '',
        status: 'paid',
        vat: 0,
        working_schedule_time_slot: '',
        doctor_id: '',
        therapyIds: [],
        items: [],
        welfare_application_id: '',
        welfare_discount: 0,
        total_paid: 0,
        invoice_service_type: '',
        no_of_session_payment: null,
        is_followup: ''
      },
      invoiceServiceType: [
          { label: "ZCF", value: "ZCF" },
          { label: "PHT", value: "PHT" },
          { label: "CP", value: "CP" },
          { label: "Autism", value: "Autism" },
          { label: "Rickets/Bow Leg", value: "Rickets/Bow Leg" },
          { label: "Dental", value: "Dental" },
      ],
      phaseType: [
        { label: "Treatment", value: 0 },
        { label: "Followup", value: 1 }
      ],
      patients: [],

      appointments: [],
      memberTypes: [],
      employees: [],
      search_employee: '',
      phone_number: '',
      is_preview: false,
      packages: [],
      assistants: [],
      chambers: [],
      package: {},
      therapies: [],
      welfare_details: {
        expected_discount_amount: 0,
        discount_due: 0
      },
      is_welfare_discount: '',
    };
  },
  created() {
    this.getConnectPatient();
    this.getPackageList();
  },
  mounted() {
    this.memebershipTypes();
    this.getPhysioAssistants();
    if (!this.$route.query.p) {
      this.getTherapyList();
    }
  },
  computed: {
    ...mapGetters('auth', ['userInstitute', 'authUser']),
    subtotal() {
      if(['Corporate', 'Corporate Family'].includes(this.service_invoice.membership_type_id ) && this.service_invoice.employee_id) {
        const total_discount = parseFloat(this.service_invoice.discount_percentage);
        const discountAmount = this.package.price * (total_discount / 100);
        
        return this.package.price - discountAmount;
      } else if (this.package.discount < this.service_invoice.discount_percentage) {
        const discountAmount = this.package.price * (this.service_invoice.discount_percentage / 100);
        return this.package.price - discountAmount;
      } 
      
      
      // else if (this.welfare_details.expected_discount_amount) { 
      //   const total_discount = parseFloat(this.service_invoice.discount_percentage);
      //   const discountAmount = this.package.price * (total_discount / 100);
      //   return this.package.price - discountAmount;
      // }
      return this.package.total;
    },
    total() {
      if(this.is_welfare_discount) {
        return this.subtotal - this.service_invoice.welfare_discount;
      }

      return this.subtotal;
    },
  },
  methods: {
    createNewUser() {
      if(this.service_invoice.membership_type_id == 'Doctor Referral') {
        const routeData = this.$router.resolve({path: '/outside/doctors', query: {create: "yea"}});
        window.open(routeData.href, '_blank');
      } else {
        const routeData = this.$router.resolve({path: '/brokers', query: {create: "yea"}});
        window.open(routeData.href, '_blank');
      }
    },
    calculatePaidAmount() {
      this.service_invoice.appointments = [];
      let perSessionCost = this.total?Math.ceil(this.total/this.package.no_of_sessions):0;
      this.service_invoice.total_paid = perSessionCost*this.service_invoice.no_of_session_payment;
      for (let i = 0; i < this.service_invoice.no_of_session_payment; i++) {
        this.service_invoice.appointments.push({
          schedule_date: '',
          schedule_started_at: '',
          schedule_end_at: '',
          workplace_id: '',
          working_schedule_time_slot: '',
          chambers: [],
        });
      }
    },
    clearPackageSelection() {
      this.package = {};
      this.service_invoice.no_of_session_payment= null;
      this.calculatePaidAmount();
    },
    changePatient() {
      this.service_invoice.orko_therapy_package_id = '';
      this.service_invoice = [];
      this.package = {};
      this.service_invoice.no_of_session_payment= null;
      this.calculatePaidAmount();
    },
    addMinutes(time, minsToAdd) {
      function D(J) {
        return (J < 10 ? '0' : '') + J;
      }
      const piece = time.split(':');
      const mins = piece[0] * 60 + +piece[1] + +minsToAdd;

      return `${D(mins % (24 * 60) / 60 | 0)}:${D(mins % 60)}`;
    },
    getPhysioAssistants() {
      this.loading = true;
      axios.get('/api/v2/doctor/center-wise/list?sub_type=physio_assistant&appointment=yes')
        .then((response) => {
          this.assistants = response.data.data;
          this.loading = false;
        });
    },
    dateChanged() {
      this.appointment_id = null;
      this.service_invoice.appointments = [];
      this.service_invoice.therapyIds = [];
      this.service_invoice.orko_therapy_package_id = '';
      this.package = {};
    },
    getConnectPatient() {
      const url = '/api/v2/partner/agent/patients';
      axios.get(url)
        .then((response) => {
          this.patients = response.data.data;
        });
    },
    getPackageList() {
      axios.get('/api/v2/therapy-packages')
        .then((response) => {
          this.packages = response.data.data;
        });
    },
    getTherapyList() {
      axios.get('/api/v2/partner/therapy/list')
        .then((response) => {
          this.therapies = response.data.data;
        });
    },
    searchGlobalPatient(value) {
      if (value) {
        axios
          .get(`/api/v1/patient/subordinate/list?term=${value}`)
          .then((response) => {
            this.patients = response.data.data;
          });
      }
    },
    getDiscount(total, discountPercent) {
      const discountAmount = total * (discountPercent / 100);
      return total - discountAmount;
    },
    previewInvoice(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          // this.service_invoice.patient_id = this.$route.query.patient_id;
          if (this.service_invoice.therapyIds.length) {
            this.service_invoice.items = [];
            for (let i = 0; i < this.service_invoice.therapyIds.length; i++) {
              const therapyObj = this.therapies.find((item) => item.id == this.service_invoice.therapyIds[i]);
              this.service_invoice.items.push(
                {
                  id: this.service_invoice.therapyIds[i], title: therapyObj ? therapyObj.title : '', quantity: 1, unit_price: this.total, total: this.total,
                },
              );
            }
          }
          this.is_preview = true;
        } else {
          return true;
        }
      });
    },

    todayDate() {
      let today = new Date();
      const dd = String(today.getDate())
        .padStart(2, '0');
      const mm = String(today.getMonth() + 1)
        .padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      today = `${yyyy}-${mm}-${dd}`;
      return today;
    },

    memebershipTypes() {
      axios.get('/api/v2/membership-types')
        .then((res) => {
          this.memberTypes = res.data.data;
        });
    },
    getServiceType(servicetype) {
      const serviceTypeObj = this.invoiceServiceType.find((item) => item.label == servicetype);
      return serviceTypeObj.label;
    },
    getEmployees(value) {
      let type = '';
      if (this.service_invoice.membership_type_id == 'Doctor Referral') {
        type = 'doctor';
      } else if (this.service_invoice.membership_type_id == 'Corporate') {
        type = 'corporate';
      } else if (this.service_invoice.membership_type_id == 'Corporate Family') {
        type = 'corporate-family';
      } else if (this.service_invoice.membership_type_id == 'Employee Family') {
        type = 'employee-family';
      } else if (this.service_invoice.membership_type_id == "General Referral") {
        type = "broker";
      } else {
        type = 'employee';
      }

      axios
        .get(`/api/v2/search/members?membership_type=${this.service_invoice.membership_type_id}&type=${type}&q=${value || ''}`)
        .then((res) => {
          if (value) {
            this.employees = res.data.data;
          }
        });
    },

    checkMembershipType() {
      // this.service_invoice.employee_id
      const membershipType = this.memberTypes.find(
        (item) => item.name == this.service_invoice.membership_type_id,
      );
      const selectedEmp = this.employees.find(
        (item) => item.id == this.service_invoice.employee_id,
      );

      if (selectedEmp) {
        if(['Corporate', 'Corporate Family'].includes(this.service_invoice.membership_type_id ) && this.service_invoice.employee_id) {
          this.service_invoice.discount_percentage = parseFloat(selectedEmp.discount) + parseFloat(this.package.discount);
        } else if(['Doctor Referral', 'Employee Referral'].includes(this.service_invoice.membership_type_id ) ){
          this.service_invoice.discount_percentage=0;  
        } else {
          this.service_invoice.discount_percentage = selectedEmp.discount; 
        }

        // this.service_invoice.discount_percentage = selectedEmp.discount; 
        this.service_invoice.discount_reason = membershipType.name;
      } else {
        this.service_invoice.discount_percentage = '';
      }

      this.calculatePaidAmount();
    },
    changeEmployeeType() {
      this.service_invoice.employee_id = '';
      this.service_invoice.discount_percentage = '';
      this.employees = [];
    },
    checkDoctorReferral() {
      axios
        .get(`/api/v2/check/doctor-referral?patient_id=${this.service_invoice.patient_id}`)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.employees.push(res.data.data); 
            this.service_invoice.employee_id = res.data.data.id;
            this.service_invoice.membership_type_id = 'Doctor Referral';
            this.service_invoice.discount = res.data.data.discount;
            this.service_invoice.discount_reason = 'Doctor Referral';
          }
        });  
    },
    checkItemValue(min, max, itemObj, field) {
      if(itemObj[field] > max) {
        itemObj[field] = max;
      } else if(itemObj[field] < min) {
        itemObj[field] = min;
      }
    },
    formDataNull() {
      this.service_invoice.slot_value = '';
    },
    disabledDate(time) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      return time.getTime() < date;
    },
    clickDate(date_value, index) {
      this.service_invoice.appointments[index].chambers = [];
      this.loading = true;
      const url = `/api/v1/doctor/chamber/list?schedule_date=${date_value}&doctor_id=${this.service_invoice.doctor_id}${this.service_invoice.service_type == 'visit' ? '' : '&type=telemedicine'}`;
      axios
        .get(url)
        .then((res) => {
          this.service_invoice.appointments[index].chambers = res.data.data;
          this.loading = false;
        });
    },
    clickPackage() {
      this.package = this.packages.find((item) => item.id == this.service_invoice.orko_therapy_package_id);
      this.package.total = this.package.price;
      this.package.discount = 0;
      // this.service_invoice.discount_percentage = this.package.discount;
      this.service_invoice.discount_percentage = 0;
      this.service_invoice.appointments = [];
      this.service_invoice.no_of_session_payment= null;
      this.calculatePaidAmount();
      // for (let i = 0; i < this.package.no_of_sessions; i++) {
      //   this.service_invoice.appointments.push({
      //     schedule_date: '',
      //     schedule_started_at: '',
      //     schedule_end_at: '',
      //     workplace_id: '',
      //     working_schedule_time_slot: '',
      //     chambers: [],
      //   });
      // }
    },

    workPlaceId(workplace, index) {
      this.service_invoice.appointments[index].workplace_id = workplace.id;
      this.service_invoice.appointments[index].working_schedule_time_slot = workplace.working_schedules[0].time_per_slot;
    },
    clickVisit() {},
    clickVirtual() {},
    calculateMultiplePercentage(discount) {
      if(['Corporate', 'Corporate Family'].includes(this.service_invoice.membership_type_id) && this.service_invoice.employee_id) {
        return parseFloat(this.service_invoice.discount_percentage);
      }else if (discount < parseFloat(this.service_invoice.discount_percentage)) {
        return parseFloat(this.service_invoice.discount_percentage)
      }
      return discount;
    },
    checkWelfareDiscount(){
      if(this.is_welfare_discount) {
        axios.get(`/api/v2/welfare/applications/${this.$route.query.patient_id?this.$route.query.patient_id:this.service_invoice.patient_id}/check`)
        .then(res => {
          if(res.data.data) {
          ElMessage({
              message: 'Yes, you got discount',
              type: 'success',
          })
          this.welfare_details = res.data.data;
          this.service_invoice.welfare_discount = this.welfare_details.discount_due;
          this.calculatePaidAmount();
        }else {
          
          ElMessage({
              message: 'Sorry, patient is not Eligible',
              type: 'error',
          })
        }
        })
      }else {
        this.welfare_details = {};
      }
    },

  },
  watch: {
    'service_invoice.doctor_id': function (oldValue, newVal) {
      if (newVal) {
        this.service_invoice.appointments = [];
        this.package = this.packages.find((item) => item.id == this.service_invoice.orko_therapy_package_id);
        this.service_invoice.appointments = [];
        if (!this.package) {
          return false;
        }
        for (let i = 0; i < this.package.no_of_sessions; i++) {
          this.service_invoice.appointments.push({
            schedule_date: '',
            schedule_started_at: '',
            schedule_end_at: '',
            workplace_id: '',
            working_schedule_time_slot: '',
            chambers: [],
          });
        }
      }
    },
  },

};
</script>

<style>
.w-100 {
  width: 100% !important;
}
</style>
<style scoped>
.text-center {
  text-align: center !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th,
td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.text-right {
  text-align: right;
}
.row-gap {
  margin-bottom: 15px;
}
</style>

